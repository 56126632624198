/*
 * @Author: your name
 * @Date: 2020-12-22 14:43:51
 * @LastEditTime: 2022-01-20 14:56:18
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /ids-admin-web的副本/src/apis/user.js
 */
import createAPI from '@/common/createApi.js'
/**
 * config: 接口参数，
 * vconfig: （自定义参数）{
 *  neverLoading: 是否显示loading
 * }
 */
const api = '/we-api/user'
/*&verifyCode=55*/
/* const api = '' */
export default {
  // 获取邮箱验证码
  getEmailCode: (email, code, config) =>
    createAPI(
      `${api}/v1/user/getEmailVerifyCode?email=${email}&verifyCode=${code}`,
      'get',
      config,
      {
        isLoading: false
      }
    ),
  emailIsExist: (email, config) =>
    createAPI(
      `${api}/v1/user/checkEmailIsExist?email=${email}`,
      'get',
      config,
      {
        isLoading: false
      }
    ),
  getPhoneCode: (email, code, config) =>
    createAPI(
      `${api}/v1/user/getPhoneVerifyCode?phone=${email}&verifyCode=${code}`,
      'get',
      config,
      {
        isLoading: false
      }
    ),
  phoneIsExist: (phone, config) =>
    createAPI(
      `${api}/v1/user/checkPhoneIsExist?phone=${phone}`,
      'get',
      config,
      {
        isLoading: false
      }
    ),
  registerAuthor: config =>
    createAPI(`${api}/v1/user/register`, 'post', config, {
      isLoading: false
    }),
  getImageCode: (identify, config) =>
    createAPI(
      `${api}/v1/user/code/image/get?identify=${identify}`,
      'get',
      config,
      {
        isLoading: false,
        neverLoading: true
      }
    ),
  checkImageCode: (identify, code, config) =>
    createAPI(
      `${api}/v1/user/code/image/check?identify=${identify}&code=${code}`,
      'get',
      config,
      {
        isLoading: false
      }
    )
}
